<template>
  <div class="registration" @click.self="showGroup = false">
    <div class="form" @click.self="showGroup = false">
      <div class="title">Зарегистрироваться</div>
      <div class="subtitle">
        После отправки формы вы получите письмо с подтверждением почты и паролем
        от входа в аккаунт
      </div>
      <div class="input-container ic2">
        <input
          id="email"
          class="input"
          type="text"
          placeholder=" "
          v-model="userData.email"
          @click.self="showGroup = false"
        />
        <div class="cut cut-short"></div>
        <label for="email" class="placeholder">Email</label>
      </div>
      <div class="input-container ic2">
        <input
          id="email"
          class="input"
          type="text"
          placeholder=" "
          v-model="userData.name"
          @click.self="showGroup = false"
        />
        <div class="cut cut-short"></div>
        <label for="email" class="placeholder">Имя</label>
      </div>
      <div class="input-container ic2">
        <input
          id="email"
          class="input"
          type="text"
          placeholder=" "
          v-model="nameGroup"
          @click="showGroup = !showGroup"
          style="cursor: pointer"
        />
        <div class="cut cut-short" style="width: 130px"></div>
        <label for="email" class="placeholder">Возврастная группа</label>

        <div class="group-list" v-if="showGroup">
          <div
            class="group-list__item"
            v-for="(group, index) in groupList"
            :key="index"
            @click="updateGroup(group.id, group.name)"
          >
            {{ group.name }}
          </div>
        </div>
      </div>
      <button type="text" class="submit" @click="registration">
        Отправить
      </button>
      <p style="color: #eee">
        Скачать наше приложение можете по сслкам ниже или отсканировав QR-код
      </p>
      <div class="info">
        <p>
          <a
            href="https://play.google.com/store/games?hl=ru&gl=US"
            target="_top"
          >
            <img class="info__google" src="@/assets/googlePlay.png" alt="" />
          </a>
        </p>
        <p>
          <a
            href="https://play.google.com/store/games?hl=ru&gl=US"
            target="_top"
          >
            <img class="info__apple" src="@/assets/appStore.png" alt="" />
          </a>
        </p>
      </div>
    </div>
  </div>
  <RegistrationSuccess v-if="showMessage" />
  <RegistrationError v-if="showError" :text="descriptionError" />
</template>

<script>
import axios from "axios";
import { ref, onMounted } from "vue";
import RegistrationSuccess from "@/components/RegistrationSuccess.vue";
import RegistrationError from "@/components/RegistrationError.vue";
import { useRoute } from "vue-router";
export default {
  components: {
    RegistrationSuccess,
    RegistrationError,
  },
  setup() {
    const route = useRoute();
    const partnerId = ref(null);

    const userData = ref({
      email: "",
      name: "",
      age_group: 0,
      referrer: 0,
    });

    const showError = ref(false);

    onMounted(() => {
      partnerId.value = route.fullPath.split("=");
      userData.value.referrer = partnerId.value[1];
    });
    const registration = () => {
      return axios
        .post("https://backend.notalone.su/api/v1/registration/", {
          email: userData.value.email,
          name: userData.value.name,
          age_group: Number(userData.value.age_group),
          referrer: Number(userData.value.referrer),
        })
        .then(() => {
          openShowMessage();
        })
        .catch((e) => {
          descriptionError.value = e.response.data.description;
          showError.value = true;
          setTimeout(() => {
            showError.value = false;
          }, 4000);
        });
    };

    const showMessage = ref(false);
    const openShowMessage = () => {
      showMessage.value = true;
      setTimeout(() => {
        showMessage.value = false;
      }, 5000);
    };

    const groupList = ref([
      { id: 0, name: "От 0 до 16 лет" },
      { id: 1, name: "От 16 до 25 лет" },
      { id: 2, name: "От 25 до 40 лет" },
      { id: 3, name: "40+ лет" },
    ]);

    const nameGroup = ref("От 0 до 16 лет");
    const showGroup = ref(false);
    const updateGroup = (id, name) => {
      userData.value.age_group = id;
      nameGroup.value = name;
      showGroup.value = false;
    };

    const descriptionError = ref("");
    return {
      userData,
      registration,
      showMessage,
      openShowMessage,
      groupList,
      showGroup,
      updateGroup,
      nameGroup,

      descriptionError,
      showError,
    };
  },
};
</script>

<style lang="scss">
.group-list {
  background: #ffffff;
  border-radius: 5px;
  &__item {
    padding: 10px;
    background: #ffffff;
    color: #333;
    cursor: pointer;
    &:hover {
      background: #3d4383;
      color: #fff;
    }
  }
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__google {
    width: 200px;
    height: 115px;
  }
  &__apple {
    width: 200px;
    height: 60px;
  }
}
.registration {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  background: #15172b;
}

.form {
  background-color: #15172b;
  border-radius: 20px;
  padding: 0px 20px;
  width: 420px;
  box-shadow: 0px 0px 4px 4px #4d539d;
}

.title {
  color: #eee;
  font-size: 36px;
  font-weight: 600;
  margin-top: 20px;
}

.subtitle {
  color: #eee;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 90%;
}

.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #a739fb;
}

.submit {
  background-color: #8f00ff;
  border-radius: 12px;
  border: 0;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 18px;
  text-align: center;
  width: 80%;
}

.submit:active {
  background-color: #8f00ff;
}
a,
a:link,
a:hover,
a:visited {
  color: #808097;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .form {
    width: 300px;
  }
  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 14px;
  }

  .submit {
    font-size: 14px;
    height: 40px;
    margin-top: 18px;
    width: 80%;
  }

  .input-container {
    height: 40px;
  }
  .input {
    font-size: 14px;
  }

  .cut {
    left: 10px;
    width: 45px;
  }

  .placeholder {
    left: 10px;
    top: 20px;
    font-size: 14px;
  }
}
.info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__google {
    width: 150px;
    height: 85px;
  }
  &__apple {
    width: 150px;
    height: 45px;
  }
}

@media screen and (max-width: 370px) {
  .form {
    width: 260px;
  }
  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 10px;
  }

  .submit {
    font-size: 14px;
    height: 35px;
    margin-top: 18px;
    width: 80%;
  }

  .input-container {
    height: 40px;
  }
  .input {
    font-size: 14px;
  }

  .cut {
    left: 10px;
    width: 45px;
  }

  .placeholder {
    left: 10px;
    top: 20px;
    font-size: 14px;
  }
  p {
    margin-bottom: 0;
    font-size: 10px;
  }
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__google {
      width: 130px;
      height: 75px;
    }
    &__apple {
      width: 120px;
      height: 40px;
    }
  }
}
</style>
