<template>
    <div class="rigistration-success">
        <div class="rigistration-success__body">
            <div class="rigistration-success__body_title">Регистрация прошла успешно</div>
            <div class="rigistration-success__body_subtitle">Пароль для входа в приложение отправлен на вашу почту. Скачать приложение можно по ссылкам</div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss">
    .rigistration-success{
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.392);
        display: table;
        transition: opacity 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        &__body{
            width: 500px;
            background: #15172b;
            padding: 10px 20px;
            border-radius: 10px;
            box-shadow: 0px 0px 4px 0px #4d54a1;
            &_title{
                color: #FFFFFF;
                font-size: 22px;
            }
            &_subtitle{
                color: #ffffffdd;
                font-size: 16px;
                margin-top: 5px;
            }
        }
    }

    @media screen and (max-width: 570px) {
        .rigistration-success{
            &__body{
                width: 80vw;
                background: #15172b;
                padding: 10px 20px;
                border-radius: 10px;
                box-shadow: 0px 0px 4px 0px #4d54a1;
                &_title{
                    color: #FFFFFF;
                    font-size: 18px;
                }
                &_subtitle{
                    color: #ffffffdd;
                    font-size: 12px;
                }
            }
        }
    }
    @media screen and (max-width: 370px) {
        .rigistration-success{
            &__body{
                width: 80vw;
                background: #15172b;
                padding: 10px 20px;
                border-radius: 10px;
                box-shadow: 0px 0px 4px 0px #4d54a1;
                &_title{
                    color: #FFFFFF;
                    font-size: 14px;
                }
                &_subtitle{
                    color: #ffffffdd;
                    font-size: 10px;
                }
            }
        }
    }
</style>